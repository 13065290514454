<template>
  <v-data-table
    :headers="headers"
    :items="itemRawProducts"
    :items-per-page="15"
    :footer-props="{
      itemsPerPageOptions: [10, 25, 50, -1]
    }"
    dense
  >
    <!-- <template v-slot:item.diameter="{ item }">
      {{
        item.attributes.dimensions.diameter &&
          `${item.attributes.dimensions.diameter} ${item.attributes.dimensionsUnit}`
      }}
    </template> -->
    <template v-slot:item.dimensions="{ item }">
      {{
        item.attributes.dimensions.length &&
          `L ${item.attributes.dimensions.length}`
      }}

      {{
        item.attributes.dimensions.width &&
          `x W${item.attributes.dimensions.width}`
      }}

      {{
        item.attributes.dimensions.height &&
          `x T${item.attributes.dimensions.height}`
      }}
      {{
        (item.attributes.dimensions.length ||
          item.attributes.dimensions.width ||
          item.attributes.dimensions.height) &&
          item.attributes.dimensionsUnit
      }}

      {{
        item.attributes.dimensions.diameter &&
          `/ ⌀${item.attributes.dimensions.diameter} ${item.attributes.dimensionsUnit}`
      }}
    </template>
    <template v-slot:item.attributes.volume="{ item }">
      <div v-if="item.attributes.volume">
        {{ item.attributes.volume }}
        {{ item.attributes.volumeUnit || '-' }}
      </div>
    </template>
    <template v-slot:item.attributes.name="{ item }">
      {{ $t(`inventory.categories.${item.attributes.name}`) }}</template
    >
    <template v-slot:item.actions="{ item }">
      <!-- <ItemRawProductIndexTableActions :key="item.id" :item="item" /> -->
      <BaseTableActions
        module-name="inventoryItem"
        :item="item"
        path="es/inventory/items/"
        :to-show="{
          name: 'inventory-item-show',
          params: { inventoryItemId: item.id }
        }"
        :to-edit="{
          name: 'item-raw-product-edit',
          params: {
            itemRawProductId: item.id
          }
        }"
      />
    </template>
  </v-data-table>
</template>

<script>
// import ItemRawProductIndexTableActions from './ItemRawProductIndexTableActions'
import BaseTableActions from '@/components/buttons/BaseTableActions'
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config'
export default {
  name: 'ItemRawProductIndexTable',
  components: {
    BaseTableActions
    // ItemRawProductIndexTableActions
  },
  data() {
    return {
      facilities: [],
      itemGroupPallets: []
    }
  },
  computed: {
    ...mapGetters({
      itemResource: 'itemResource/itemResource',
      itemRawProducts: 'itemRawProduct/items'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.facilities = await this.fetchFacilities()
      // this.itemGroupPallets = await this.fetchItemGroupPallets()
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    getFacility(facilityId) {
      if (!facilityId || !this.facilities.length) return
      const {
        attributes: { name }
      } = this.facilities.find(facility => facility.id === facilityId)
      return name
    },
    // onClick(item) {
    //   this.$router.push({
    //     name: 'inventory-item-show',
    //     params: {
    //       inventoryItemId: item.id
    //     }
    //   })
    // },
    createTableHeaders() {
      return [
        {
          text: this.$t('item.reference'),
          value: 'attributes.description',
          sortable: false
        },
        {
          text: this.$tc('item.name', 1),
          value: 'attributes.name',
          sortable: false
        },
        {
          text: this.$tc('item.unit', 2),
          sortable: false,
          value: 'attributes.itemUnitsCount'
        },
        {
          text: 'L x W x T / ⌀',
          sortable: false,
          value: 'dimensions'
        },
        // {
        //   text: this.$t('item.diameter'),
        //   sortable: false,
        //   value: 'diameter'
        // },
        {
          text: this.$t('item.volume'),
          sortable: false,
          value: 'attributes.volume'
        },
        // // { text: 'Cost', sortable: false, value: 'attributes.costPrice' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
        // { text: '', value: 'data-table-expand' }
      ]
    }
  }
}
</script>
