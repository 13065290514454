<template>
  <v-card flat height="180">
    <v-card-title class="text-h6"
      ><v-icon left>mdi-pine-tree</v-icon>{{ $t('item.woodDetails') }}
    </v-card-title>
    <v-divider></v-divider>
    <table v-if="itemQuotation.data.attributes" class="mt-3 mb-5 mx-3">
      <thead>
        <tr>
          <th
            v-for="(value, name, i) in itemQuotation.data.attributes
              .customAttributes"
            :key="i"
            class="grey--text text--darken-2 text-caption font-weight-bold"
          >
            {{ $t(`item.${name}`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="(value, name, j) in itemQuotation.data.attributes
              .customAttributes"
            :key="j"
            class="font-weight-bold grey--text text--darken-3"
          >
            {{ (name === 'specie' ? value.text : value) || '-' }}
          </td>
        </tr>
      </tbody>
    </table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      itemQuotation: 'itemQuotation/itemQuotation'
    })
  }
}
</script>
<style lang="scss" scoped>
table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  padding: 0.5rem;
  text-align: left;
}
</style>
