<template>
  <v-card flat>
    <v-card-title class="text-h6"
      ><v-icon left>mdi-details</v-icon>{{ $t('common.details') }}
    </v-card-title>
    <v-divider></v-divider>
    <table v-if="item" class="mt-3 mb-5 mx-3">
      <thead>
        <tr>
          <th
            v-for="(prop, i) in props"
            :key="i"
            class="grey--text text--darken-2 text-caption font-weight-bold"
          >
            {{ prop | titleCase }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <template v-for="(prop, j) in props">
            <td
              v-if="prop === 'dimensions'"
              :key="j"
              class="font-weight-bold grey--text text--darken-3"
            >
              {{ item.dimensions.length && ` L ${item.dimensions.length}` }}
              {{ item.dimensions.width && ` W ${item.dimensions.width}` }}
              {{ item.dimensions.height && ` T ${item.dimensions.height}` }}
              {{ item.dimensionsUnit || '-' }}
            </td>
            <td
              v-else-if="prop === 'diameter'"
              :key="j"
              class="font-weight-bold grey--text text--darken-3"
            >
              {{ item.dimensions.diameter && ` ${item.dimensions.diameter}` }}
              {{ item.dimensionsUnit || '-' }}
            </td>
            <td
              v-else-if="prop === 'volume'"
              :key="j"
              class="font-weight-bold grey--text text--darken-3"
            >
              {{ item.volume || '-' }}
              {{ item.volumeUnit || '-' }}
            </td>
            <td
              v-else
              :key="j"
              class="font-weight-bold grey--text text--darken-3"
            >
              {{ item[prop] || item.customAttributes[prop] }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <ItemResourceBlockchain />

    <ItemResourceInstructions />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemResourceBlockchain from './ItemResourceBlockchain'
import ItemResourceInstructions from './ItemResourceInstructions'
export default {
  components: {
    ItemResourceBlockchain,
    ItemResourceInstructions
  },
  data() {
    return {
      props: [
        // 'name',
        'dimensions',
        'diameter',
        'volume',
        'marking',
        'quality'
      ]
    }
  },
  computed: {
    ...mapGetters({ itemResource: 'itemResource/itemResource' }),
    item() {
      if (!this.itemResource.data) return
      const {
        // name,
        dimensions: { length, width, height, diameter },
        dimensionsUnit,
        volume,
        volumeUnit,
        customAttributes: {
          marking,
          quality,
          // diameter,
          // diameterUnit,
          instructions
        }
      } = this.itemResource.data.attributes
      return {
        // name,
        dimensions: { length, width, height, diameter },
        dimensionsUnit,
        volume,
        volumeUnit,
        customAttributes: {
          marking,
          quality,
          // diameter,
          // diameterUnit,
          instructions
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  padding: 0.75rem 0.5rem;
  text-align: left;
}
</style>
