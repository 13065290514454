<template>
  <v-select
    v-if="option"
    :value="option"
    single-line
    hide-details
    solo
    :background-color="option.color"
    flat
    dense
    return-object
    :items="computedOptions"
    :disabled="false"
    @change="$emit('change', $event)"
  ></v-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'StatusSelector',
  props: {
    status: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      itemResource: 'itemResource/itemResource'
    }),

    option() {
      return this.options.find(o => o.status === this.status)
    },
    computedOptions() {
      return this.options
    }
  }
}
</script>
