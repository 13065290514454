<template>
  <v-btn
    depressed
    class="ml-2"
    color="success"
    :disabled="disabled"
    :loading="isLoading"
    @click="onClick"
  >
    <v-icon left>{{
      itemResource && itemResource.data.attributes.status === 'out_stock'
        ? 'mdi-check'
        : 'mdi-send-outline'
    }}</v-icon>
    {{
      itemResource && itemResource.data.attributes.status === 'out_stock'
        ? $t('actions.confirmed')
        : $t('actions.confirmItems')
    }}
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    itemResource() {
      return this.$store.getters['itemResource/itemResource']
    },
    disabled() {
      return (
        !this.itemResource?.data.id ||
        this.itemResource?.data.attributes.status === 'out_stock'
      )
    }
  },
  methods: {
    async onClick() {
      this.isLoading = true
      const hash = await this.createTransactionHash()
      await this.updateItemResource(hash)
      this.isLoading = false
    },
    async createTransactionHash() {
      const response = await this.$store.dispatch(
        'blockchain/create',
        this.itemResource
      )
      return response.result
    },
    async updateItemResource(hash) {
      const itemResource = { ...this.itemResource.data }
      itemResource.attributes.status = 'out_stock'
      itemResource.attributes.customAttributes.blockchain = hash
      await this.$store.dispatch('itemResource/update', itemResource)
    }
  }
}
</script>

<style lang="scss" scoped></style>
