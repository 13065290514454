<template>
  <v-list v-if="blockchain">
    <v-list-item
      :href="`https://rinkeby.etherscan.io/tx/${blockchain}`"
      target="_blank"
      class="grey lighten-5"
    >
      <v-list-item-content>{{
        $t('blockchain.registration')
      }}</v-list-item-content>
      <v-list-item-content
        class="font-weight-bold text-body-2 purple--text text--lighten-1"
      >
        <span class="d-inline-block text-truncate" style="max-width: 600px;">
          {{ blockchain || '-' }}
        </span></v-list-item-content
      ></v-list-item
    >
  </v-list>
</template>

<script>
export default {
  computed: {
    blockchain() {
      const item = this.$store.getters['itemResource/itemResource']
      const { blockchain } = item?.data.attributes.customAttributes
      return blockchain
    }
  }
}
</script>
