var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemRawProducts,"items-per-page":15,"footer-props":{
    itemsPerPageOptions: [10, 25, 50, -1]
  },"dense":""},scopedSlots:_vm._u([{key:"item.dimensions",fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.dimensions.length && `L ${item.attributes.dimensions.length}`)+" "+_vm._s(item.attributes.dimensions.width && `x W${item.attributes.dimensions.width}`)+" "+_vm._s(item.attributes.dimensions.height && `x T${item.attributes.dimensions.height}`)+" "+_vm._s((item.attributes.dimensions.length || item.attributes.dimensions.width || item.attributes.dimensions.height) && item.attributes.dimensionsUnit)+" "+_vm._s(item.attributes.dimensions.diameter && `/ ⌀${item.attributes.dimensions.diameter} ${item.attributes.dimensionsUnit}`)+" ")]}},{key:"item.attributes.volume",fn:function({ item }){return [(item.attributes.volume)?_c('div',[_vm._v(" "+_vm._s(item.attributes.volume)+" "+_vm._s(item.attributes.volumeUnit || '-')+" ")]):_vm._e()]}},{key:"item.attributes.name",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`inventory.categories.${item.attributes.name}`)))]}},{key:"item.actions",fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"inventoryItem","item":item,"path":"es/inventory/items/","to-show":{
        name: 'inventory-item-show',
        params: { inventoryItemId: item.id }
      },"to-edit":{
        name: 'item-raw-product-edit',
        params: {
          itemRawProductId: item.id
        }
      }}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }