<template>
  <v-container fluid>
    <v-alert v-if="showAlert" tile outlined dense type="info">{{
      $t('alerts.markAsReceived')
    }}</v-alert>
    <BaseLayoutTitle
      :prefix="
        itemResourceDescription ||
          (itemResourceName && $t(`inventory.categories.${itemResourceName}`))
      "
      :title="$t('common.details')"
    >
      <template v-slot:selector>
        <StatusSelector
          class="ml-10"
          :status="itemResource.data.attributes.status"
          :options="options"
          @change="updateItemStatus"
      /></template>
    </BaseLayoutTitle>

    <ItemQuotationDetails />
    <ItemResourceDetails />
    <ItemRawProductIndex class="mt-5" :item-resource-id="itemResourceId" />
    <DocumentIndex
      class="my-4"
      :resource-id="itemResourceId"
      resource-type="Item"
    />
    <base-scroll-up-button />
  </v-container>
</template>

<script>
import ItemQuotationDetails from '@/views/item-quotation/components/ItemQuotationDetails'
import ItemResourceDetails from '@/views/item-resource/components/ItemResourceDetails'
import StatusSelector from '@/components/StatusSelector'
import ItemRawProductIndex from '@/views/item-raw-product/components/ItemRawProductIndex'
import DocumentIndex from '@/views/document/components/DocumentIndex'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    ItemQuotationDetails,
    ItemResourceDetails,
    StatusSelector,
    ItemRawProductIndex,
    DocumentIndex
  },
  props: {
    itemResourceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      options: [
        {
          text: this.$t('resourceStatus.pending'),
          status: 'pending',
          color: 'amber lighten-5'
        },
        {
          text: this.$t('resourceStatus.processing_pending'),
          status: 'processing_pending',
          color: 'amber lighten-5'
        },
        {
          text: this.$t('resourceStatus.in_stock'),
          status: 'in_stock',
          color: 'green lighten-5'
        },
        {
          text: this.$t('resourceStatus.out_stock'),
          status: 'out_stock',
          color: 'red lighten-5'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ itemResource: 'itemResource/itemResource' }),
    itemResourceDescription() {
      return this.itemResource.data.attributes.description
    },
    itemResourceName() {
      return this.itemResource.data.attributes.name
    },
    showAlert() {
      return this.itemResource.data.attributes.status === 'pending'
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    ...mapActions({
      updateItemResource: 'itemResource/update',
      fetchItemQuotation: 'itemQuotation/fetch',
      fetchItemResource: 'itemResource/fetch'
    }),
    async fetchData() {
      this.isLoading = true
      const { data } = await this.fetchItemResource(this.itemResourceId)
      const itemQuotationId = data.relationships.parent.data.id
      await this.fetchItemQuotation(itemQuotationId)
      this.isLoading = false
    },
    async updateItemStatus({ status }) {
      const itemResource = {
        ...this.itemResource.data,
        ...{ attributes: { status } }
      }
      return await this.updateItemResource(itemResource)
    }
  }
}
</script>
