<!--
-->
<template>
  <base-secondary-table-layout
    icon="mdi-cogs"
    :card-title="$t('rawProduct.processedItems')"
  >
    <template v-slot:button>
      <v-spacer></v-spacer>
      <base-new-outlined-button
        v-if="itemResource"
        :to="{
          name: 'item-raw-product-new',
          params: { itemResourceId }
        }"
        >{{ $tc('item.name', 1) }}</base-new-outlined-button
      >
      <ItemResourceConfirmationButton />
    </template>

    <ItemRawProductIndexTable />
  </base-secondary-table-layout>
</template>

<script>
import ItemRawProductIndexTable from './ItemRawProductIndexTable'
import ItemResourceConfirmationButton from './ItemResourceConfirmationButton'
import { mapGetters } from 'vuex'
export default {
  components: {
    ItemRawProductIndexTable,
    ItemResourceConfirmationButton
  },
  props: {
    itemResourceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      headers: [
        {
          text: this.$t('common.details'),
          sortable: false,
          value: 'attributes.details'
        },
        {
          text: 'Units',
          sortable: false,
          value: 'attributes.quantity'
        },
        {
          text: 'Length',
          sortable: false,
          value: 'dimensions'
        },
        {
          text: 'Volume',
          sortable: false,
          value: 'volume'
        },
        {
          text: 'Qr Code',
          value: 'qrCode',
          sortable: false,
          align: 'center'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({ itemResource: 'itemResource/itemResource' })
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterItemRawProduct()
      this.isLoading = false
    },
    async filterItemRawProduct() {
      return await this.$store.dispatch('itemRawProduct/filter', {
        parentId: this.itemResourceId
      })
    }
  }
}
</script>
