<template>
  <v-card flat>
    <v-card-title class="text-h6 my-5">
      <v-icon left>mdi-circular-saw</v-icon>
      {{ $t('resource.cuttingInstructions') }}
      <v-spacer></v-spacer>
      <v-btn v-show="disabled" icon @click="disabled = false"
        ><v-icon>mdi-pencil</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-textarea
        :value="itemResource.data.attributes.customAttributes.instructions"
        :label="$t('resource.cuttingInstructions')"
        auto-grow
        rows="1"
        filled
        :disabled="disabled"
        background-color="grey lighten-3"
        @input="onInput"
      ></v-textarea>
    </v-card-text>
    <v-card-actions v-if="!disabled">
      <v-spacer></v-spacer>
      <v-btn :loading="isLoading" depressed @click="onClick()">{{
        $t('actions.save')
      }}</v-btn></v-card-actions
    >
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      instructions: '',
      disabled: true
    }
  },
  computed: {
    itemResource() {
      return this.$store.getters['itemResource/itemResource']
    }
  },
  methods: {
    onInput(value) {
      this.instructions = value
    },
    async onClick() {
      this.isLoading = true
      await this.updateItemResoruce()
      this.isLoading = false
      this.disabled = true
    },

    async updateItemResoruce() {
      const itemResource = { ...this.itemResource }
      itemResource.data.attributes.customAttributes.instructions = this.instructions
      await this.$store.dispatch('itemResource/update', itemResource.data)
    }
  }
}
</script>
